/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background: linear-gradient(to bottom, #0c3e63, #89CFF0); /* Soft light blue sky gradient */
  color: white;
  margin: 0;
  text-align: center; /* Center all text by default */
}

/* ********************************************************************************************************************* */


.title-container {
  position: relative;
  width: 100%;
  max-width: 1400px;
  margin: 50px auto 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 350px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 191, 255, 0.7);
  padding: 20px;
}

.game-title {
  font-size: 5rem;
  font-weight: 700;
  color: #ffffff;
  text-shadow: 0 0 20px rgba(0, 191, 255, 0.8),
               0 0 28px rgba(0, 191, 255, 0.6),
               0 0 36px rgba(0, 191, 255, 0.4);
  margin: 0;
  animation: flow 6s ease-in-out infinite;
}

@keyframes flow {
  0% {
    text-shadow: 0 0 20px rgba(0, 191, 255, 0.8),
                 0 0 28px rgba(0, 191, 255, 0.6),
                 0 0 36px rgba(0, 191, 255, 0.4);
  }
  50% {
    text-shadow: 0 0 40px rgba(0, 191, 255, 1),
                 0 0 50px rgba(0, 191, 255, 0.8),
                 0 0 60px rgba(0, 191, 255, 0.6);
  }
  100% {
    text-shadow: 0 0 20px rgba(0, 191, 255, 0.8),
                 0 0 28px rgba(0, 191, 255, 0.6),
                 0 0 36px rgba(0, 191, 255, 0.4);
  }
}

.crypto-icons {
  display: flex;
  gap: 20px;
  font-size: 3rem;
  margin-top: 20px;
}

.crypto-icons > span {
  transition: transform 0.3s ease, filter 0.3s ease;
  cursor: pointer;
}

.crypto-icons > span:hover {
  transform: scale(1.5) rotate(10deg);
  filter: drop-shadow(0 0 10px rgba(0, 191, 255, 0.9));
}

/* Side Emojis with more interactivity and inward positioning */
.side-left, .side-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 6rem; /* Larger size */
  transition: transform 0.5s ease, filter 0.5s ease;
  cursor: pointer;
}

.side-left {
  left: 60px; /* Move slightly inward */
}

.side-right {
  right: 60px; /* Move slightly inward */
}

.side-left img, .side-right img {
  width: 6rem; /* Match the emoji font size */
  height: 6rem; /* Keep aspect ratio consistent */
  object-fit: contain; /* Prevent image distortion */
  transition: transform 0.5s ease, filter 0.5s ease; /* Add smooth hover effects */
  cursor: pointer;
}

.side-left img:hover, .side-right img:hover {
  transform: scale(1.3) rotate(15deg) translateY(-20px); /* Apply scaling, rotation, and upward movement */
  filter: drop-shadow(0 0 20px rgba(0, 191, 255, 1)); /* Glow effect on hover */
}

.description-container {
  position: relative;
  width: 100%;
  max-width: 1150px;
  margin: 30px auto;
  padding: 30px;
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.05), rgba(0, 191, 255, 0.15)); /* Softer gradient */
  border-radius: 20px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3); /* Deeper shadow for more depth */
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(10px); /* Blur background for more visual effect */
}

/* Game Description */
.game-description {
  font-size: 1.5rem; /* Adjusted for readability */
  line-height: 1.6; /* Improved readability */
  color: #ffffff;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  margin: 0;
  padding: 0 20px; /* Padding to give space around the text */
  max-width: 800px;
  text-align: center; /* Align text left for better flow */
  flex: 1;
}

/* Trivia Icon Styling */
.trivia-icon {
  width: 80px;
  height: 80px;
  transition: transform 0.3s ease, filter 0.3s ease;
  filter: drop-shadow(0 0 10px rgba(0, 191, 255, 0.6)); /* Soft initial shadow */
}

/* Left and Right Icon Adjustments */
.left-icon {
  margin-right: 20px;
  transform: rotate(-15deg);
}

.right-icon {
  margin-left: 20px;
  transform: rotate(15deg);
}

/* Hover Effect for Trivia Icons */
.trivia-icon:hover {
  transform: scale(1.3) rotate(0); /* More dramatic hover effect */
  filter: drop-shadow(0 0 25px rgba(0, 191, 255, 1)); /* Stronger glow on hover */
}





/* ********************************************************************************************************************* */

/* Main Screen */
.main-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px); /* Glassmorphism effect */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  max-width: 900px;
  margin: auto;
  margin-top: 10px;
  width: 90%;  /* Ensure it takes 90% of the screen for consistent size */
  min-height: 400px; /* Ensure all containers have a minimum height */
  color: white;
  text-align: center; /* Center the text inside the main screen */
}

/* Consistent container sizes for wallet and forms */
.wallet-container,
.username-container {
  width: 100%;
  max-width: 600px;
  background: rgba(0, 0, 50, 0.5);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Wallet Connect Button */
.connect-wallet-button {
  background: linear-gradient(145deg, #3B82F6, #60A5FA); /* Gradient for modern look */
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 15px 50px; /* Increased padding for a longer button */
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.3s, background 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  width: 100%; /* Make button take full width */
  max-width: 500px; /* Longer button for visual balance */
}

.connect-wallet-button:hover {
  transform: translateY(-5px);
  background: linear-gradient(145deg, #2563EB, #3B82F6);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* Lifting hover effect */
}

.network-buttons-container {
  text-align: center; /* Centering everything in this section */
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}



.lace-wallet-instructions {
  margin-top: 20px;
  text-align: center;
  align-items: center;
}

.lace-link {
  display: inline-flex; /* Use only inline-flex */
  align-items: center; /* Aligns text and logo vertically */
  justify-content: center;
  margin-top: 10px;
  text-decoration: none;
  color: #00bfff;
  font-weight: bold;
  font-size: 1.1rem; /* Make text size similar to the logo */
  transition: color 0.3s ease;
}

.lace-link:hover {
  color: #ffffff;
}

.lace-logo {
  align-items: center;
  width: 20px;
  height: auto;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.lace-link:hover .lace-logo {
  transform: scale(1.05); /* Slight zoom effect on hover */
}


.network-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

/* Username Creation Form */
.username-container h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.username-container p {
  font-size: 1rem;
  color: lightgray;
  margin-bottom: 15px;
}

.username-input {
  text-align: center; /* Centers the text inside the input field */
  padding: 12px;
  font-size: 1rem;
  border-radius: 10px;
  border: none;
  margin-top: 10px;
  width: 100%;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
  color: #fff;
}

.create-account-button {
  background: linear-gradient(145deg, #34D399, #4ADE80); /* Green gradient */
  padding: 12px 24px;
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
  font-size: 1rem;
  margin-top: 15px;
  width: 100%; /* Full-width for consistent styling */
  max-width: 300px;
}

.create-account-button:hover {
  background: linear-gradient(145deg, #28B469, #34D399);
  transform: translateY(-3px);
}

/* Disconnect Button */
.disconnect-wallet-button {
  padding: 12px 24px;
  font-size: 1.2rem;
  font-weight: bold;
  background: linear-gradient(145deg, #FF3B3B, #FF6F6F); /* Red gradient for disconnect */
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, background 0.3s;
  margin-top: 15px;
  width: 100%; /* Full-width for consistency */
  max-width: 400px;
}

.disconnect-wallet-button:hover {
  transform: translateY(-3px);
  background: linear-gradient(145deg, #E63946, #F76C6C);
}

/* Message Box */
.message-box {
  margin-top: 30px;
  padding: 15px 30px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  border-radius: 15px;
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 700px;
  word-wrap: break-word; /* Prevent text from overflowing */
  min-height: 50px; /* Consistent message box size */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Responsive design for mobile */
@media (max-width: 768px) {
  .main-screen {
    width: 100%;
    padding: 20px;
  }

  .game-title {
    font-size: 2.5rem;
  }

  .connect-wallet-button,
  .disconnect-wallet-button {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .username-input {
    width: 100%;
  }
}

/* ********************************************************************************************************************* */

/* Game Container */
.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  max-width: 900px;
  margin: 20px auto;
  width: 90%;
  min-height: 500px;
  color: white;
  text-align: center;
  position: relative; /* To control quit popup z-index */
}

/* XP Display Container */
.xp-container {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  padding: 20px;
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  text-align: center;
}

.xp-title {
  font-size: 1.5rem;
  color: #fff;
}

.xp-value {
  font-size: 2rem;
  color: #FFD700; /* Gold for the XP value */
  font-weight: bold;
}

/* Daily Question Container */
.daily-question-container {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 700px;
  margin: 20px auto;
  color: white;
  text-align: center;
}

/* Title for the Daily Question */
.daily-question-container h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #ffffff;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}

/* Instruction Text */
.daily-question-container .instructions {
  font-size: 1.2rem;
  color: #ffffff;
  margin-bottom: 20px;
}

/* Question Text */
.daily-question-container .question {
  font-size: 1.4rem;
  color: white;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

/* Daily Answers Button Container */
.daily-answers {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

/* Answer Buttons */
.answer-btn {
  background: linear-gradient(145deg, #60A5FA, #3B82F6);
  color: white;
  border: none;
  border-radius: 10px;
  padding: 15px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: transform 0.3s, background 0.3s;
}

.answer-btn:hover {
  transform: translateY(-3px);
  background: linear-gradient(145deg, #3B82F6, #2563EB);
}

.answer-btn.selected {
  background: #34D399;
}

/* Submit Button */
.submit-btn {
  background: linear-gradient(145deg, #34D399, #4ADE80);
  padding: 12px 24px;
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
  margin-top: 20px;
  font-size: 1rem;
}

.submit-btn:hover {
  background: linear-gradient(145deg, #28B469, #34D399);
  transform: translateY(-3px);
}

/* Feedback Message */
.feedback {
  font-size: 1.2rem;
  margin-top: 20px;
  color: #ffeb3b;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}


/* Instructions Section */
.instructions-container {
  background: rgba(0, 0, 50, 0.8);
  padding: 20px;
  border-radius: 15px;
 
  margin: 20px auto;
  width: 100%;
  max-width: 900px;
  text-align: center;
  color: #fff;
}

.instructions-container p {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.instructions-container ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.instructions-container li {
  font-size: 1.1rem;
  display: flex;
  align-items: center;
}

/* Level Color Code Circles */
.instructions-container li span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.locked-color {
  background-color: grey;
}

.completed-color {
  background-color: gold;
  box-shadow: 0 0 10px gold;
}

.unlocked-color {
  background-color: #60A5FA; /* Blue */
}

.selected-color {
  background-color: #34D399; /* Green */
}

/* Topics Section */
.topics-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 0;
}

.topic-btn {
  padding: 15px 30px;
  background: linear-gradient(145deg, #3B82F6, #60A5FA); /* Blue gradient */
  color: white;
  border: none;
  border-radius: 15px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s, background 0.3s;
}

.topic-btn.selected {
  background: linear-gradient(145deg, #34D399, #4ADE80); /* Green for selected */
  transform: scale(1.05);
}

.topic-btn:disabled {
  background-color: grey;
  cursor: not-allowed;
  opacity: 0.6;
}

.topic-btn:hover:enabled {
  background: linear-gradient(145deg, #2563EB, #3B82F6);
}

/* Category Section */
.category-container {
  background: rgba(255, 255, 255, 0.05);
  padding: 10px;
  border-radius: 15px;
  margin: 20px auto;
  max-width: 900px;
  text-align: center;
  color: white;
}

.categories-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.category-group {
  background: rgba(0, 0, 50, 0.8);
  padding: 20px;
  border-radius: 15px;
  min-width: 200px;
  text-align: center;
}

.category-name {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #ffffff;
}

/* Level Buttons */
.levels {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.level-btn {
  padding: 10px 20px;
  font-size: 1.1rem;
  font-weight: bold;
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.level-btn.locked {
  background-color: grey;
  cursor: not-allowed;
}

.level-btn.completed {
  background-color: gold;
  box-shadow: 0 0 10px gold;
}

.level-btn.unlocked {
  background-color: #60A5FA; /* Blue for unlocked */
}

.level-btn.selected {
  background-color: #34D399; /* Green for selected */
  box-shadow: 0 0 10px #34D399;
}

.level-btn:hover:enabled {
  transform: scale(1.05);
}

/* Start Game Button */
.start-game-btn {
  padding: 15px 30px;
  background: linear-gradient(145deg, #4CAF50, #66BB6A);
  color: white;
  border: none;
  border-radius: 15px;
  font-size: 1.5rem; /* Increased font size for better visibility */
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.3s ease;
  width: 300px; /* Longer width for better visibility */
  text-align: center; /* Center the text */
  box-shadow: 0 0 15px rgba(76, 175, 80, 0.6); /* Glow effect */
}

/* Hover effect for Start Game Button */
.start-game-btn:hover {
  transform: scale(1.1); /* Slightly larger hover effect */
  background: linear-gradient(145deg, #388E3C, #4CAF50);
  box-shadow: 0 0 25px rgba(76, 175, 80, 1); /* Stronger glow on hover */
}



/* ********************************************************************************************************************* */

/* Quit Game Button */
.quit-game-btn {
  padding: 10px 20px;
  font-size: 1.1rem;
  font-weight: bold;
  background: linear-gradient(145deg, #FF3B3B, #FF6F6F);
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, background 0.3s;
  margin-bottom: 20px;
  float: right;
}

.quit-game-btn:hover {
  transform: scale(1.05);
  background: linear-gradient(145deg, #E63946, #F76C6C);
}

/* Score Box */
.score-box {
  display: flex;
  justify-content: space-around;
  padding: 15px 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 1.2rem;
  width: 100%; /* Spans across the top under the quit button */
}

.score-box p {
  margin: 0;
}

/* Question and Answers */
.question-container {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 30px;
  border-radius: 15px;
  margin-bottom: 30px;
  width: 100%;
}

.question {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center; /* Centering the question */
}

/* Answers arranged in a column */
.answers {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px; /* Gap between buttons */
}

.answer-btn {
  background: #60A5FA;
  padding: 15px;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
}

.answer-btn:hover {
  background: #3B82F6;
  transform: scale(1.05);
}

.answer-btn.correct {
  background-color: #34D399; /* Green for correct */
}

.answer-btn.incorrect {
  background-color: #FF3B3B; /* Red for incorrect */
}

/* Feedback */
.feedback {
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 20px;
  color: white; /* Default white for waiting feedback */
}

.feedback.correct {
  color: #34D399; /* Green for correct */
}

.feedback.incorrect {
  color: #FF3B3B; /* Red for incorrect */
}

/* Timer */
.timer {
  font-size: 1.1rem;
  margin-top: 10px;
  color: white; /* Keep timer text white */
}

/* Explanation Popup - consistent size with min height */
.popup-container {
  background-color: rgba(0, 0, 50, 0.85);
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  min-height: 150px; /* Set minimum height */
  max-height: 300px; /* Set maximum height */
  overflow-y: auto;
  width: 100%; /* Spans the full width of the container */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: space-between; /* Ensure button stays at the bottom */
}

.popup-text {
  font-size: 1.2rem;
  color: #fff;
  line-height: 1.5;
  text-align: center; /* Center the text inside the explanation box */
  flex-grow: 1; /* Allow the text to take up space in the container */
}

/* Popup Button - positioned below the explanation */
.popup-button {
  padding: 12px 24px;
  background: linear-gradient(145deg, #34D399, #4ADE80);
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 15px;
  transition: transform 0.3s, background 0.3s;
  align-self: center; /* Center the button horizontally */
}

.popup-button:hover {
  transform: scale(1.05);
  background: linear-gradient(145deg, #2BB673, #34D399);
}

/* Final Score Popup */
.final-score-container {
  background-color: rgba(0, 50, 100, 0.9);
  padding: 30px;
  border-radius: 15px;
  max-width: 600px;
  margin: 40px auto;
  text-align: center;
  color: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.6);
}

.final-score-message {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.final-score {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.acknowledge-button {
  padding: 12px 24px;
  background: linear-gradient(145deg, #4CAF50, #66BB6A);
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: transform 0.3s, background 0.3s;
}

.acknowledge-button:hover {
  transform: scale(1.05);
  background: linear-gradient(145deg, #388E3C, #4CAF50);
}

/* Quit Popup */
.quit-popup-container {
  background-color: rgba(255, 0, 0, 0.9);
  padding: 30px;
  border-radius: 15px;
  color: white;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.quit-message {
  font-size: 1.4rem;
  margin-bottom: 20px;
}

/* Popup buttons container */
.popup-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* Cancel and Confirm Quit buttons */
.cancel-button, .confirm-quit-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.2s ease;
  width: 45%;
}

/* Cancel Button */
.cancel-button {
  background-color: #4CAF50; /* Green for not quitting */
  color: white;
}

.cancel-button:hover {
  transform: scale(1.05);
}

/* Confirm Quit Button */
.confirm-quit-button {
  background-color: #ff4d4d; /* Red for quitting */
  color: white;
}

.confirm-quit-button:hover {
  transform: scale(1.05);
}


/* ********************************************************************************************************************* */

/* Leaderboard Container */
.leaderboard-container {
  width: 90%;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(0, 50, 100, 0.8);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  color: white;
  font-family: 'Arial', sans-serif;
  margin-top: 40px;
  height: 500px; /* Set height to show more rows */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
}

/* Leaderboard Title */
.leaderboard-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Refresh Button */
.refresh-button {
  padding: 10px 20px;
  background-color: #2c7e60;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 10px;
  width: fit-content; /* Make the button width fit the text */
  align-self: center; /* Align the button with the title */
}

.refresh-button:hover {
  background-color: #10B981;
  transform: scale(1.05);
}

/* Leaderboard */
.leaderboard {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 350px; /* Height to show 7-10 rows */
  scrollbar-width: thick;
  scrollbar-color: #4ADE80 rgba(255, 255, 255, 0.1);
  margin-top: 20px; /* Reduced gap above the rows */
}

/* Leaderboard Row */
.leaderboard-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
  transition: background-color 0.3s, transform 0.3s;
}

.leaderboard-row:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

/* Rank Styling */
.rank {
  font-size: 1.5rem;
  font-weight: bold;
  color: #FFD700;
  flex-basis: 10%;
  text-align: left;
}

/* Username Styling */
.username {
  font-size: 1.2rem;
  color: #FFFFFF;
  flex-basis: 40%;
  text-align: center; /* Center the username horizontally */
  display: flex;
  justify-content: center;
  align-items: center; /* Vertically center the username */
}

/* XP Styling */
.xp {
  font-size: 1.2rem;
  color: #4ADE80;
  text-align: right;
  flex-basis: 10%;
  display: flex;
  justify-content: flex-end; /* Ensure right-alignment */
  align-items: center; /* Vertically center the XP */
}

/* Scrollbar Styling */
.leaderboard::-webkit-scrollbar {
  width: 8px;
}

.leaderboard::-webkit-scrollbar-thumb {
  background-color: #4ADE80;
  border-radius: 10px;
}

.leaderboard::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
}

/* ********************************************************************************************************************* */

/* About Section Container */
.about-section {
  background-color: rgba(255, 255, 255, 0.08); /* Subtle background for contrast */
  border-radius: 10px;
  padding: 20px 30px;
  max-width: 900px;
  margin: 20px auto;
  color: #FFFFFF;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Soft shadow */
  line-height: 1.5;
  font-size: 1.1rem;
  text-align: justify;
}

/* About Section Title */
.about-section h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #FFFFFF; /* Keep it white for a clean look */
  text-align: center;
  font-weight: 600;
}

/* About Section Paragraphs */
.about-section p {
  margin-bottom: 15px; /* Reduce spacing between paragraphs */
}

/* Remove green lines, no special dividers */

/* ********************************************************************************************************************* */


/* Team Section */
.team-section {
  background-color: rgba(255, 255, 255, 0.08); /* Subtle background for contrast */
  border-radius: 10px;
  padding: 30px;
  max-width: 900px;
  margin: 20px auto;
  color: #FFFFFF;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Soft shadow */
  text-align: center; /* Center all content */
}

/* Team Section Title */
.team-section h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #FFFFFF; /* White title for a clean look */
  font-weight: 600;
}

/* Profile Section */
.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Profile Picture */
.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-picture:hover {
  transform: scale(1.05); /* Slight hover effect to enlarge the image */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

/* Social Media Section */
.social-media {
  margin-top: 10px;
}

.social-media a {
  color: #1DA1F2; /* Twitter blue */
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
}

.social-media a:hover {
  text-decoration: underline;
}

.social-media i {
  margin-right: 5px; /* Space between Twitter icon and handle */
  font-size: 1.3rem;
}
